import React, { useCallback } from 'react';
import HeroSection from '../../components/HeroSection';
import Section from '../../components/Section';
import Card from '../../components/Card';
import Container from '../../components/Container';
import { Heading1, Paragraph } from '../../components/Typography';
import ErrorSVG from '../../images/error-circle.svg';
import { Link, navigate } from 'gatsby';
import Button from '../../components/Button';

function FormSubmitted() {
    return (
        <div>
            <HeroSection />
            <Section
                backgroundColor='grey'
                paddingTop='74px'
                paddingBottom='80px'
            >
                <Container mx='auto'>
                    <Card
                        p={40}
                        maxWidth='400px'
                        mx='auto'
                        justifyContent='center'
                        style={{ display: 'flex', flexDirection: 'column' }}
                    >
                        <Heading1 textAlign='center'>
                            Form Submit Error
                        </Heading1>
                        <img src={ErrorSVG} alt='' />
                        <Paragraph textAlign='center'>
                            Please{' '}
                            <Link to='/web-services/three-month-plan'>
                                try again
                            </Link>{' '}
                            or if the error continues please{' '}
                            <Link to='/contact'>contact us</Link>.
                        </Paragraph>
                        <Button
                            height='40px'
                            onClick={useCallback(() => navigate('/'), [])}
                        >
                            Return to homepage
                        </Button>
                    </Card>
                </Container>
            </Section>
        </div>
    );
}

export default FormSubmitted;
